// ./src/components/objects/CardContext.js

import React, { createContext, useState } from "react";
import PocketBase from "pocketbase";

export const CardContext = createContext();

const apiKeys = [
  process.env.REACT_APP_API_KEY_1,
  process.env.REACT_APP_API_KEY_2,
  process.env.REACT_APP_API_KEY_3,
  process.env.REACT_APP_API_KEY_4,
  process.env.REACT_APP_API_KEY_5,
];

export const CardProvider = ({ children }) => {
  const pb = new PocketBase("https://fwk4s4o.app-palma.teide.app");
  const [cards, setCards] = useState([]);
  const [error, setError] = useState("");

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getRandomApiKey = () => {
    const randomIndex = Math.floor(Math.random() * apiKeys.length);
    return apiKeys[randomIndex];
  };

  const createResultRecords = async (cards) => {
    for (const card of cards) {
      try {
        const userId = pb.authStore.model?.id || null;
        await pb.collection("results").create({
          user: userId,
          tcgseid:
            card.set.id +
            "/" +
            card.set.id +
            "-" +
            card.number +
            "/" +
            card.set.id +
            "_" +
            card.number,
          releasedate: card.set.releaseDate,
          trend: parseFloat(card.cardmarket?.prices?.trendPrice) || null,
          cardmarket:
            parseFloat(card.cardmarket?.prices?.averageSellPrice) || null,
          tcgplayer: parseFloat(card.tcgplayer?.prices?.normal?.market) || null,
          tcgplayernormallow:
            parseFloat(card.tcgplayer?.prices?.normal?.low) || null,
          tcgplayernormalmid:
            parseFloat(card.tcgplayer?.prices?.normal?.mid) || null,
          tcgplayernormalhigh:
            parseFloat(card.tcgplayer?.prices?.normal?.high) || null,
          tcgplayernormaldirectlow:
            parseFloat(card.tcgplayer?.prices?.normal?.directLow) || null,
          tcgplayerreversehololow:
            parseFloat(card.tcgplayer?.prices?.reverseHolofoil?.low) || null,
          tcgplayerreverseholomid:
            parseFloat(card.tcgplayer?.prices?.reverseHolofoil?.mid) || null,
          tcgplayerreverseholohigh:
            parseFloat(card.tcgplayer?.prices?.reverseHolofoil?.high) || null,
          tcgplayerreverseholomarket:
            parseFloat(card.tcgplayer?.prices?.reverseHolofoil?.market) || null,
          tcgplayerreverseholodirectlow:
            parseFloat(card.tcgplayer?.prices?.reverseHolofoil?.directLow) ||
            null,
          cardmarketurl: card.cardmarket?.url || null,
          cardmarketupdatedat: card.cardmarket?.updatedAt || null,
          cardmarketaveragesellprice:
            parseFloat(card.cardmarket?.prices?.averageSellPrice) || null,
          cardmarketlowprice:
            parseFloat(card.cardmarket?.prices?.lowPrice) || null,
          cardmarkettrendprice:
            parseFloat(card.cardmarket?.prices?.trendPrice) || null,
          cardmarketgermanprolow:
            parseFloat(card.cardmarket?.prices?.germanProLow) || null,
          cardmarketsuggestedprice:
            parseFloat(card.cardmarket?.prices?.suggestedPrice) || null,
          cardmarketreverseholosell:
            parseFloat(card.cardmarket?.prices?.reverseHoloSell) || null,
          cardmarketreversehololow:
            parseFloat(card.cardmarket?.prices?.reverseHoloLow) || null,
          cardmarketreverseholotrend:
            parseFloat(card.cardmarket?.prices?.reverseHoloTrend) || null,
          cardmarketlowpriceexplus:
            parseFloat(card.cardmarket?.prices?.lowPriceExPlus) || null,
          cardmarketavg1: parseFloat(card.cardmarket?.prices?.avg1) || null,
          cardmarketavg7: parseFloat(card.cardmarket?.prices?.avg7) || null,
          cardmarketavg30: parseFloat(card.cardmarket?.prices?.avg30) || null,
          cardmarketreverseholoavg1:
            parseFloat(card.cardmarket?.prices?.reverseHoloAvg1) || null,
          cardmarketreverseholoavg7:
            parseFloat(card.cardmarket?.prices?.reverseHoloAvg7) || null,
          cardmarketreverseholoavg30:
            parseFloat(card.cardmarket?.prices?.reverseHoloAvg30) || null,
          name: card.name,
          hasability: card.abilities ? true : false,
          supertype: card.supertype,
          subtype0: card.subtypes[0] || "N/A",
          subtype1: card.subtypes[1] || "N/A",
          subtype2: card.subtypes[2] || "N/A",
          number: `${card.number}/${card.set.total}`,
          about: `${card.set.name} - ${card.set.id}`,
          set: card.set.name,
          regulation: card.regulationMark || "N/A",
          imagesout: card.images.small,
          imagelout: card.images.large,
          fileout: "https://api.pokemontcg.io/v2/cards/" + card.id,
          imagesin:
            "https://epicevils.com/b/" +
            card.set.id +
            "/" +
            card.set.id +
            "-" +
            card.number +
            "/" +
            "small_" +
            card.set.id +
            "_" +
            card.number +
            ".png",
          imagelin:
            "https://epicevils.com/b/" +
            card.set.id +
            "/" +
            card.set.id +
            "-" +
            card.number +
            "/" +
            "large_" +
            card.set.id +
            "_" +
            card.number +
            ".png",
          filein:
            "https://epicevils.com/b/" +
            card.set.id +
            "/" +
            card.set.id +
            "-" +
            card.number +
            "/" +
            card.set.id +
            "-" +
            card.number +
            ".json",
        });
      } catch (error) {
        console.error(`Code 00A00001`, error);
      }
    }
  };

  const fetchCards = async (filters) => {
    const { name, type, regulationMark, set } = filters;

    if (!name || name.length < 3 || name.length > 25) {
      setError("Search term must be between 3 and 25 characters.");
      return;
    }

    let nameParts = name ? name.split(" ") : [];
    let queryParts = [
      ...nameParts.map((part) => `name:${encodeURIComponent(part)}*`),
      type ? `types:${encodeURIComponent(type)}` : "",
      // Agregar el filtro de set si está presente
      set && set !== "Any Set" ? `set.name:"${encodeURIComponent(set)}"` : "",
    ].filter(Boolean); // Filtrar valores vacíos

    let regulationMarks =
      regulationMark === "F,G,H"
        ? ["F", "G", "H"]
        : regulationMark
          ? [regulationMark]
          : [];
    let sets = [];
    let allCards = [];
    let page = 1;
    let hasMoreResults = true;
    const limit = 250;

    try {
      const apiKey = getRandomApiKey();
      const query = queryParts.join(" ");

      while (hasMoreResults) {
        let response;

        if (!regulationMarks.length) {
          response = await fetch(
            `https://api.pokemontcg.io/v2/cards?q=${query}&orderBy=releaseDate desc&page=${page}&pageSize=${limit}`,
            {
              headers: { "X-Api-Key": apiKey },
            }
          );
        } else {
          let combinedResults = [];
          for (let mark of regulationMarks) {
            let markResponse = await fetch(
              `https://api.pokemontcg.io/v2/cards?q=${query} regulationMark:${mark}&orderBy=releaseDate desc&page=${page}&pageSize=${limit}`,
              {
                headers: { "X-Api-Key": apiKey },
              }
            );
            let markData = await markResponse.json();
            combinedResults = combinedResults.concat(markData.data || []);
          }
          response = { json: async () => ({ data: combinedResults }) };
        }

        let data = await response.json();
        allCards = allCards.concat(data.data || []);
        hasMoreResults = data.data && data.data.length === limit;
        page++;
      }

      allCards.sort((a, b) => {
        const order = { H: 1, G: 2, F: 3 };
        const aOrder = order[a.regulationMark] || 4;
        const bOrder = order[b.regulationMark] || 4;

        if (aOrder !== bOrder) {
          return aOrder - bOrder;
        }

        return new Date(b.set.releaseDate) - new Date(a.set.releaseDate);
      });

      if (allCards.length > 0) {
        setCards(allCards);
        await createResultRecords(allCards);
      } else {
        setCards([]);
        throw new Error("No cards found");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchCardsByIds = async (ids) => {
    try {
      const apiKey = getRandomApiKey();
      const responses = await Promise.all(
        ids.map((id) =>
          fetch(`https://api.pokemontcg.io/v2/cards/${id}`, {
            headers: { "X-Api-Key": apiKey },
          })
        )
      );
      const data = await Promise.all(responses.map((res) => res.json()));
      return data.map((res) => res.data);
    } catch (error) {
      setError(error.message);
      return [];
    }
  };

  return (
    <CardContext.Provider
      value={{ pb, cards, setCards, error, fetchCards, fetchCardsByIds }}
    >
      {children}
    </CardContext.Provider>
  );
};
