// ./src/utils/typesIcons.js

export const typesIcons = {
    Psychic: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/r3ydhr36rscnq63/psychic_yTIDZSagKc.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Metal: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/84txqskfuaqtxnl/steal_ckCv5N68vT.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Lightning: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/qkcg6npmbclc3n4/lightning_yQctSjDojR.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Grass: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/ffq2nxcyu67msmr/grass_gMQzQ3wVpC.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Fire: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/9j0j0dn8yl9n5sl/fire_QSPMdt12en.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Fighting: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/udm6a6p06u6x1gs/fightning_L5YLcRZtXD.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Fairy: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/5a5ed0do84xcvq6/fairy_xnCg1vwDlu.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Dragon: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/sjje3dir71g64em/dragon_SdGEDgZb8Z.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Darkness: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/krtxm0qbtk9uw61/dark_F1ZKUj59dP.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Colorless: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/o5lm496jrdoduw4/colorless_fxD0Twa3jD.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Water: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/gms6ig2t9xfbyuh/water_m2EogghOm9.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTg5OTAsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.OWumZliYtqF8McEmGlui8VopUnEApSKIzABgYt0YBUA"
};

